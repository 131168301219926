//
// Extended from bootstrap
//

// Badge dot

.badge-dot {
    font-size: 0;
    vertical-align: middle;
    padding: 0;
    border-radius: 50%;
    line-height: 1;
    height: .5rem;
    width: .5rem;
    display: inline-block !important;


}
