// Gallery Grid scss

.gallery {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(6, 5vw);
  grid-gap: 0.5rem;
  .gallery__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
  .gallery__item--1 {
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 4;
  }

  .gallery__item--2 {
    grid-column-start: 6;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 4;
  }
  .gallery__item--3 {
    grid-column-start: 9;
    grid-column-end: 13;
    grid-row-start: 1;
    grid-row-end: 4;
  }
  .gallery__item--4 {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 4;
    grid-row-end: 7;
  }
  .gallery__item--5 {
    grid-column-start: 4;
    grid-column-end: 9;
    grid-row-start: 4;
    grid-row-end: 7;
  }
  .gallery__item--6 {
    grid-column-start: 9;
    grid-column-end: 13;
    grid-row-start: 4;
    grid-row-end: 7;
  }
}
