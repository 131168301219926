.student-grid-icon {
  margin-bottom: 20px;
}

.create-user {
  margin-bottom: 30px !important;
}

.form-control-role {
  -webkit-appearance: menulist;
}

.banner-button-group {
  text-align: right;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
