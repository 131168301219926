.transaction-amount {
    font-weight: bold;
    font-size: 32px;
}

.clear-filter {
    font-size: 20px;
    color: black;
}

.clear-filter-card {
    max-width: fit-content;
}

.clear-filter-icon:hover {
    color: royalblue;
    cursor: pointer;
}