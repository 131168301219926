.modal-content {
    padding-bottom: 1rem;
}

.editor {
    border-width: 1px !important;
    border-color: whitesmoke;
    border-style: solid;
    margin-bottom: 15px;
    padding-bottom: 70px;
}

.editor-custom {
    padding-left: 10px;
}

.curriculum-modal {
    width: 70vw;
    max-width: 80vw;
}

.delete-confirm-model {
    .modal-content {
        padding-bottom: 0;
    }
    .delete-confirm-model-header {
        .modal-title {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
    .delete-confirm-model-body {
        text-align: center;
        background: #f5f5f5;
        padding-bottom: 0;
        font-size: 1rem;
    }
    .delete-confirm-model-footer {
        background: #f5f5f5;
        border-top-width: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


.portal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000000;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);

    .confirm-dialog {
        z-index: 1000000000000111;
        padding: 0;
        background-color: white;
        width: 400px;
        position: absolute;
        top: 200px;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 5px;
    }

    .confirm-dialog__footer {
        display: flex;
        justify-content: center;
        margin: 0;
        padding-top: 15px;
        padding-bottom: 15px;
        .margin-rgt {
            margin-right: 15px;
        }
    }

    .padding-tp {
        padding-top: 15px;
    }
}
  
  