//
// Extended from switches
//

// Switch for pricing
.form-check{
  margin-bottom: 0px;
}

.form-switch-price {
  .form-check-input {
    width: 2.5em;
    height: 1.5em;

  }
  .form-check-input:checked {
    background-color: $dark-primary;
    border-color: $dark-primary;
  }
}

.form-switch {
  padding-left: $form-switch-padding-start;

  .form-check-input {
    height: 1.6em;
    width: 3em;
    border-color: $gray-200;

  }
}

.form-phone-input {
  .PhoneInputInput {
    border: none;
  }

  input {
    &:focus {
      outline: none !important;
    }
  }
}

.form-otp {
  display: grid;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  justify-content: center;
  grid-template-columns: 15% 15% 15% 15%;
}

.form-submit-loader {
  .modal-dialog {
    height: 90%;
  }
  
  .modal-content {
    border: none;
    background: none;
    text-align: center;
    margin-top: 50%;
  }

  .spinner-border {
    width: 4rem;
    height: 4rem;
  }
}
