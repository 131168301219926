//
// Extended from Bootstrap
//

// Body

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
